<template>
    <div class="small-box bg-success margen">
      <div class="inner">
      <h3>{{ payload.data.nombre_remitente }}</h3>
        <p>{{ payload.data.remitente }}</p>
      </div>
      <div class="icon">
        <i class="fa fa-phone"></i>
      </div>
      <!-- Recorrer el array de servicios y mostrar un enlace para cada uno -->
      <!-- Verificar si hay servicios antes de renderizar los enlaces -->
      <template v-if="servicios && servicios.length">
        <a 
          v-for="(servicio, index) in servicios" 
          :key="index" 
          :href="`javascript:void(0)`"
          @click="irAlServicio(servicio.id_servicio,servicio.codigo)"
          class="small-box-footer link-aligned-left">
          <!-- Mostrar servicio.mas_datos si existe -->
          <span v-if="servicio.mas_datos">{{ servicio.mas_datos }} - </span>
          {{ servicio.codigo }} <i class="fas fa-arrow-circle-right"></i>
        </a>
      </template>
    </div>

  </template>
  
  <script>
  export default {
    props: {
        payload: {
            type: Object,
            required: true
        }
    },
    data() {
      return {
        servicios: []
      };
    },    
    methods: {
      irAlServicio(idServicio,codigo) {
        localStorage.setItem('nombreservicio' + idServicio, codigo);
        this.$router.push({
            name: 'Servicio',
            params: {
                id: idServicio,
                nombreservicio: codigo,
            }
        });
      }
    },
    mounted() {
      // Deserializar los servicios cuando el componente se monta
      if (this.payload.data.servicios) {
        try {
          this.servicios = JSON.parse(this.payload.data.servicios);
        } catch (error) {
          console.error("Error al parsear los servicios:", error);
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .margen {
    padding: 10px;
    margin: 10px;
  }
  /* Alinear los enlaces a la izquierda */
  .link-aligned-left {
    display: block;
    text-align: left; /* Alinea el texto a la izquierda */
  }
  </style>